import React, { useState, useEffect, useRef } from 'react'
import { researchService } from '../../services/researchService'
import toast from 'react-hot-toast'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { motion } from 'framer-motion'
import {
  FaHistory,
  FaSearch,
  FaStar,
  FaClock,
  FaPaperPlane,
} from 'react-icons/fa'

// Configure PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

// Add these styles near the top of the file
const scrollbarStyles = `
  .custom-scrollbar::-webkit-scrollbar {
    width: 8px;
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.3);
  }
`

const styleSheet = document.createElement('style')
styleSheet.innerText = scrollbarStyles
document.head.appendChild(styleSheet)

const PDFViewer = ({ url }) => {
  const [numPages, setNumPages] = useState(null)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [scale, setScale] = useState(1.4)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
    setError(null)
    setIsLoading(false)
  }

  const onDocumentLoadError = (error) => {
    console.error('Error loading PDF:', error)
    setError(
      'Failed to load PDF. Please try refreshing the page or using a different browser.'
    )
    setIsLoading(false)
  }

  if (!url) {
    return (
      <div className="h-full bg-white rounded-lg shadow flex flex-col items-center justify-center text-gray-500 p-8">
        <svg
          className="w-16 h-16 mb-4 text-gray-400"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
          />
        </svg>
        <p className="text-lg font-medium mb-2">No PDF file specified</p>
        <p className="text-sm text-center text-gray-400 max-w-sm">
          Enter an arXiv URL above to view and analyze a research paper
        </p>
      </div>
    )
  }

  return (
    <div className="h-full bg-gray-100 rounded-lg flex flex-col">
      <div className="flex items-center justify-between p-2 border-b bg-white">
        <div className="flex items-center space-x-2">
          <button
            onClick={() => setScale((prev) => Math.max(0.5, prev - 0.1))}
            className="p-1 hover:bg-gray-100 rounded"
          >
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M20 12H4"
              />
            </svg>
          </button>
          <span className="text-sm">{Math.round(scale * 100)}%</span>
          <button
            onClick={() => setScale((prev) => Math.min(2, prev + 0.1))}
            className="p-1 hover:bg-gray-100 rounded"
          >
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 4v16m8-8H4"
              />
            </svg>
          </button>
        </div>
        <span className="text-sm text-gray-500">
          {numPages ? `${numPages} pages` : ''}
        </span>
      </div>

      <div className="flex-1 min-h-0 overflow-auto relative">
        <div className="absolute inset-0">
          <Document
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={onDocumentLoadError}
            className="h-full"
            loading={
              <div className="flex justify-center items-center h-full p-4">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-500"></div>
                <span className="ml-2">Loading PDF...</span>
              </div>
            }
            error={
              error && (
                <div className="flex flex-col items-center justify-center h-full p-4">
                  <div className="text-red-500 mb-2">⚠️ {error}</div>
                  <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-indigo-600 hover:text-indigo-800"
                  >
                    Open PDF directly
                  </a>
                </div>
              )
            }
          >
            <div className="px-4 absolute inset-0 overflow-auto">
              {Array.from(new Array(numPages), (el, index) => (
                <div key={`page_${index + 1}`} className="mb-4">
                  <Page
                    pageNumber={index + 1}
                    renderTextLayer={true}
                    renderAnnotationLayer={true}
                    scale={scale}
                    loading={
                      <div className="flex justify-center p-4 bg-gray-50 rounded">
                        Loading page {index + 1}...
                      </div>
                    }
                  />
                </div>
              ))}
            </div>
          </Document>
        </div>
      </div>
    </div>
  )
}

const AnalysisPanel = ({ data }) => {
  const [activeTab, setActiveTab] = useState('summary')

  const tabs = [
    { id: 'summary', label: 'Summary' },
    { id: 'findings', label: 'Key Findings' },
    { id: 'methodology', label: 'Methodology' },
    { id: 'discussion', label: 'Discussion' },
    { id: 'qa', label: 'Q&A' },
  ]

  return (
    <div className="h-full flex flex-col bg-white rounded-lg shadow">
      <div className="border-b flex-shrink-0">
        <nav className="flex overflow-x-auto no-scrollbar">
          <div className="flex min-w-full">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`
                  py-4 px-4 text-sm font-medium border-b-2 whitespace-nowrap flex-1
                  ${
                    activeTab === tab.id
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  }
                `}
              >
                {tab.label}
              </button>
            ))}
          </div>
        </nav>
      </div>

      <div className="flex-1 min-h-0 overflow-auto p-6">
        {activeTab === 'summary' && (
          <div className="prose max-w-none">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Paper Summary
            </h3>
            <p className="text-gray-600 text-sm">{data.summary}</p>
          </div>
        )}

        {activeTab === 'findings' && (
          <div>
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Key Findings
            </h3>
            <ul className="space-y-4">
              {data.key_findings.map((finding, index) => (
                <li key={index} className="flex items-start">
                  <span className="flex-shrink-0 h-5 w-5 text-indigo-500 mt-1">
                    <svg
                      className="h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                  <span className="ml-3 text-gray-600 text-sm">{finding}</span>
                </li>
              ))}
            </ul>
          </div>
        )}

        {activeTab === 'methodology' && (
          <div className="prose max-w-none">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Methodology
            </h3>
            <div className="space-y-4">
              {data.methodology?.map((method, index) => (
                <div key={index} className="bg-gray-50 rounded-lg p-4">
                  <h4 className="font-medium text-gray-900">{method.title}</h4>
                  <p className="text-gray-600 mt-2 text-sm">
                    {method.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}

        {activeTab === 'qa' && (
          <div>
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Questions & Answers
            </h3>
            <div className="space-y-6">
              {data.qa.map((item, index) => (
                <div key={index} className="bg-gray-50 rounded-lg p-4">
                  <div className="font-medium text-gray-900 text-sm">
                    Q: {item.question}
                  </div>
                  <div className="mt-2 text-gray-600 text-sm">
                    A: {item.answer}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {activeTab === 'discussion' && (
          <div className="prose max-w-none">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Discussion
            </h3>
            <div className="space-y-6">
              <div className="bg-gray-50 rounded-lg p-4">
                <h4 className="font-medium text-gray-900">Strengths</h4>
                <ul className="mt-2 space-y-2">
                  {data.discussion?.strengths?.map((strength, index) => (
                    <li key={index} className="text-gray-600 text-sm">
                      {strength}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="bg-gray-50 rounded-lg p-4">
                <h4 className="font-medium text-gray-900">Limitations</h4>
                <ul className="mt-2 space-y-2">
                  {data.discussion?.limitations?.map((limitation, index) => (
                    <li key={index} className="text-gray-600 text-sm">
                      {limitation}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="bg-gray-50 rounded-lg p-4">
                <h4 className="font-medium text-gray-900">Future Work</h4>
                <ul className="mt-2 space-y-2">
                  {data.discussion?.future_work?.map((work, index) => (
                    <li key={index} className="text-gray-600 text-sm">
                      {work}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const variants = {
  expanded: {
    width: '360px',
    height: '50px',
    borderRadius: '25px',
    transition: {
      duration: 0.3,
      ease: "easeOut"
    }
  },
  analyze: {
    width: '400px',
    height: '110px',
    borderRadius: '20px',
    transition: {
      duration: 0.3,
      ease: "easeOut"
    }
  },
  history: {
    width: '500px',
    height: '600px',
    borderRadius: '20px',
    transition: {
      duration: 0.3,
      ease: "easeOut"
    }
  }
}

const DynamicIsland = ({ 
  papers, 
  selectedPaper, 
  onSelectPaper,
  onSubmit,
  loading,
  isAnalyzing,
  paperUrl,
  setPaperUrl,
  activeView,
  setActiveView 
}) => {
  const islandRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (islandRef.current && !islandRef.current.contains(event.target)) {
        setActiveView(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [setActiveView])

  const handleFormSubmit = (e) => {
    e.preventDefault()
    if (!paperUrl) return
    onSubmit(paperUrl)
    setPaperUrl('')
  }

  const currentVariant = activeView === 'analyze'
    ? 'analyze'
    : activeView === 'history'
      ? 'history'
      : 'expanded'

  return (
    <motion.div
      ref={islandRef}
      initial="expanded"
      animate={currentVariant}
      variants={variants}
      className="fixed bottom-6 left-1/2 transform -translate-x-1/2 z-50"
    >
      <motion.div 
        className="w-full h-full overflow-hidden relative"
        style={{ 
          borderRadius: 'inherit',
          backgroundColor: 'rgba(75, 85, 99, 0.80)',
          backdropFilter: 'blur-lg',
        }}
      >
        {/* Content Container */}
        <motion.div 
          className="absolute inset-0 flex flex-col"
          layout
        >
          {/* Input Row */}
          {activeView === 'analyze' && (
            <motion.div 
              className="px-6 pt-3 pb-[60px] flex-shrink-0"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.2 }}
            >
              <form onSubmit={handleFormSubmit}>
                <div className="relative">
                  <input
                    type="text"
                    value={paperUrl}
                    onChange={(e) => setPaperUrl(e.target.value)}
                    placeholder="Paste arXiv URL..."
                    className="w-full px-4 py-2 rounded-lg bg-white/50 
                      text-gray-700 placeholder-gray-500/50 
                      border border-gray-400/20 focus:border-gray-400/40 
                      focus:ring-0 text-sm"
                  />
                  <button
                    type="submit"
                    disabled={loading || isAnalyzing || !paperUrl}
                    className="absolute right-2 top-1/2 -translate-y-1/2
                      px-3 py-1 rounded-lg bg-[#6B4F4F] text-white
                      disabled:opacity-50 disabled:cursor-not-allowed
                      hover:bg-gray-600 transition-colors duration-100"
                  >
                    {loading || isAnalyzing ? (
                      <motion.div
                        className="w-4 h-4 border-2 border-white border-t-transparent rounded-full"
                        animate={{ rotate: 360 }}
                        transition={{ duration: 1, repeat: Infinity, ease: 'linear' }}
                      />
                    ) : (
                      <FaPaperPlane className="w-3 h-3" />
                    )}
                  </button>
                </div>
              </form>
            </motion.div>
          )}

          {/* Buttons Row */}
          <div className="absolute bottom-0 left-1/2 w-[360px] h-[43px] -translate-x-1/2">
            <motion.button
              className={`absolute left-6 flex items-center space-x-2 px-4 py-2 rounded-lg
                font-medium transition-all duration-100
                ${activeView === 'analyze' 
                  ? 'bg-white/20 text-white'
                  : 'text-gray-200 hover:bg-white/20 hover:text-white'}`}
              onClick={() => setActiveView(activeView === 'analyze' ? null : 'analyze')}
              whileTap={{ scale: 0.98 }}
            >
              <FaSearch className="w-4 h-4" />
              <span className="text-sm">Analyze Paper</span>
            </motion.button>

            <motion.button
              className={`absolute right-6 flex items-center space-x-2 px-4 py-2 rounded-lg
                font-medium transition-all duration-100
                ${activeView === 'history' 
                  ? 'bg-white/20 text-white'
                  : 'text-gray-200 hover:bg-white/20 hover:text-white'}`}
              onClick={() => setActiveView(activeView === 'history' ? null : 'history')}
              whileTap={{ scale: 0.98 }}
            >
              <FaHistory className="w-4 h-4" />
              <span className="text-sm">{papers.length} Past Analysis</span>
            </motion.button>
          </div>

          {/* History View */}
          {activeView === 'history' && (
            <motion.div 
              className="px-6 pt-3 pb-[60px] flex-shrink-0 flex-1 overflow-hidden"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.2 }}
            >
              <div className="h-full flex flex-col">
                <div className="flex-1 overflow-y-auto custom-scrollbar">
                  <div className="space-y-2">
                    {papers.map((paper) => (
                      <motion.div
                        key={paper.id}
                        className={`cursor-pointer px-4 py-3 rounded-lg transition-all duration-150
                          ${selectedPaper?.id === paper.id 
                            ? 'bg-white/20 text-white'
                            : 'hover:bg-white/10 text-gray-200'}
                        `}
                        onClick={() => onSelectPaper(paper)}
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                      >
                        <div className="flex items-center space-x-3">
                          <div className="flex-shrink-0">
                            {paper.favorite ? (
                              <FaStar className="w-4 h-4 text-yellow-400" />
                            ) : (
                              <FaClock className="w-4 h-4 text-gray-200" />
                            )}
                          </div>
                          <div className="flex-1 min-w-0">
                            <p className="text-sm font-medium text-gray-200 truncate">
                              {paper.title || 'Untitled Paper'}
                            </p>
                            <div className="flex items-center space-x-2 mt-1">
                              <p className="text-xs text-gray-300/60">
                                {new Date(paper.created_at).toLocaleDateString()}
                              </p>
                              {paper.arxiv_id && (
                                <p className="text-xs text-gray-300/60">
                                  arXiv:{paper.arxiv_id}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </motion.div>
                    ))}
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </motion.div>
      </motion.div>
    </motion.div>
  )
}

const INITIAL_DATA = {
  pdf_url: '',
  summary: '',
  key_findings: [],
  qa: [],
  methodology: [],
  discussion: {
    strengths: [],
    limitations: [],
    future_work: [],
  },
}

const DashboardResearch = () => {
  const [paperUrl, setPaperUrl] = useState('')
  const [loading, setLoading] = useState(false)
  const [paperData, setPaperData] = useState(INITIAL_DATA)
  const [selectedText, setSelectedText] = useState('')
  const [showRelatedPapers, setShowRelatedPapers] = useState(false)
  const [pastPapers, setPastPapers] = useState([])
  const [selectedPastPaper, setSelectedPastPaper] = useState(null)
  const [taskId, setTaskId] = useState(null)
  const [analysisStatus, setAnalysisStatus] = useState(null)
  const [expandedIsland, setExpandedIsland] = useState(false)
  const [activeView, setActiveView] = useState(null) // 'analyze' or 'history' or null

  // Add new ref for completed tasks
  const completedTasksRef = useRef(new Set())
  // Add state for task progress
  const [taskProgress, setTaskProgress] = useState({})

  useEffect(() => {
    const fetchPastPapers = async () => {
      try {
        const data = await researchService.getPastPapers()
        setPastPapers(data)
      } catch (error) {
        console.error('Error fetching past papers:', error)
        toast.error('Failed to fetch past papers')
      }
    }

    fetchPastPapers()
  }, [])

  // Add function to check if all tasks are complete
  const areAllTasksComplete = (progress) => {
    if (!progress) return false
    try {
      const progressData = JSON.parse(progress)
      return Object.values(progressData).every(
        (status) => status === 'COMPLETED'
      )
    } catch (e) {
      return false
    }
  }

  const formatProgressMessage = (progress) => {
    if (!progress) return 'Starting analysis...'

    try {
      const progressData = JSON.parse(progress)

      const LoadingSpinner = ({ taskKey }) => (
        <motion.div
          key={`spinner-${taskKey}`}
          className="inline-block w-4 h-4 border-2 border-white border-t-violet-400 rounded-full"
          animate={{ rotate: 360 }}
          transition={{
            duration: 1,
            repeat: Infinity,
            ease: 'linear',
            repeatType: 'loop',
          }}
        />
      )

      const CompletedIcon = ({ taskKey }) => {
        const wasJustCompleted = !completedTasksRef.current.has(taskKey)
        if (wasJustCompleted) {
          completedTasksRef.current.add(taskKey)
        }

        return (
          <motion.div
            key={`completed-${taskKey}`}
            initial={wasJustCompleted ? { scale: 0.5, opacity: 0 } : false}
            animate={wasJustCompleted ? { scale: 1, opacity: 1 } : false}
            className="inline-flex items-center justify-center w-4 h-4 rounded-full bg-green-500 text-white"
          >
            <svg
              className="w-3 h-3"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </motion.div>
        )
      }

      const QueuedIcon = () => (
        <div className="inline-block w-4 h-4 rounded-full border-2 border-white/50" />
      )

      const getStatusIcon = (status, taskKey) => {
        switch (status) {
          case 'completed':
            return <CompletedIcon taskKey={taskKey} />
          case 'processing':
            return <LoadingSpinner taskKey={taskKey} />
          case 'queued':
            return <QueuedIcon />
          case 'failed':
            return '❌'
          default:
            return <QueuedIcon />
        }
      }

      return (
        <div className="w-[180px]">
          <div className="text-white mb-2 font-medium">Analyzing Paper</div>
          <div className="space-y-2">
            {[
              { key: 'title', label: 'Title & Summary' },
              { key: 'key_findings', label: 'Key Findings' },
              { key: 'methodology', label: 'Methodology' },
              { key: 'discussion', label: 'Discussion' },
              { key: 'qa_pairs', label: 'Q&A' },
            ].map(({ key, label }) => (
              <div key={key} className="flex items-center space-x-3">
                <div className="w-4">
                  {getStatusIcon(progressData[key], key)}
                </div>
                <span
                  className={`text-sm ${
                    progressData[key] === 'COMPLETED'
                      ? 'text-white/80'
                      : progressData[key] === 'PROCESSING'
                        ? 'text-white font-medium'
                        : 'text-white/60'
                  }`}
                >
                  {label}
                </span>
              </div>
            ))}
          </div>
        </div>
      )
    } catch (e) {
      console.error('Error formatting progress:', e)
      return 'Analyzing paper...'
    }
  }

  useEffect(() => {
    let pollInterval

    const pollAnalysisStatus = async () => {
      if (!taskId) return

      try {
        const status = await researchService.getAnalysisStatus(taskId)
        setAnalysisStatus(status)

        // Update progress toast if we have progress data
        if (status.progress) {
          toast.loading(formatProgressMessage(status.progress), {
            id: 'analysis-toast',
            duration: Infinity,
            icon: null,
          })
        }

        // Check for completed status first
        if (status.status === 'completed') {
          // Update paper data with the completed analysis
          if (status.result) {
            setPaperData(status.result)
          }

          // Refresh past papers list
          const updatedPapers = await researchService.getPastPapers()
          setPastPapers(updatedPapers)

          // Clear polling and states
          setTaskId(null)
          setAnalysisStatus(null)
          clearInterval(pollInterval)
          completedTasksRef.current = new Set()

          // Show success toast and dismiss progress toast
          toast.dismiss('analysis-toast')
          toast.success('Paper analysis completed')
          return
        }

        // Handle failed status
        if (status.status === 'failed') {
          setTaskId(null)
          setAnalysisStatus(null)
          clearInterval(pollInterval)
          completedTasksRef.current = new Set()

          toast.dismiss('analysis-toast')
          toast.error(`Analysis failed: ${status.error || 'Unknown error'}`)
          return
        }
      } catch (error) {
        console.error('Error polling status:', error)
        setTaskId(null)
        setAnalysisStatus(null)
        clearInterval(pollInterval)
        completedTasksRef.current = new Set()

        toast.dismiss('analysis-toast')
        toast.error('Failed to get analysis status')
      }
    }

    if (taskId) {
      // Reset state when starting new analysis
      completedTasksRef.current = new Set()

      // Initial toast
      toast.loading('Starting analysis...', {
        id: 'analysis-toast',
        duration: Infinity,
        icon: null,
      })

      // Start polling
      pollInterval = setInterval(pollAnalysisStatus, 2000)
      pollAnalysisStatus() // Initial poll
    }

    return () => {
      if (pollInterval) {
        clearInterval(pollInterval)
        toast.dismiss('analysis-toast')
      }
    }
  }, [taskId])

  const handleSubmit = async (url) => {
    setLoading(true)

    try {
      const arxivId = url
        .split('/')
        .pop()
        .replace('abs/', '')
        .replace('.pdf', '')
      const pdf_url = `https://export.arxiv.org/pdf/${arxivId}.pdf`

      // Update PDF URL immediately
      setPaperData((prev) => ({
        ...prev,
        pdf_url: pdf_url,
      }))

      // Start analysis and get task ID
      const response = await researchService.analyzePaper(url)
      setTaskId(response.task_id)
    } catch (error) {
      console.error('Error analyzing paper:', error)
      toast.error('Failed to start paper analysis')
    } finally {
      setLoading(false)
    }
  }

  const handleUrlChange = (e) => {
    const url = e.target.value
    setPaperUrl(url)

    // Check if it's a valid arXiv URL and update PDF URL immediately
    if (url.includes('arxiv.org/')) {
      try {
        const arxivId = url
          .split('/')
          .pop()
          .replace('abs/', '')
          .replace('.pdf', '')
        // Use PDF URL with arXiv's export format
        const pdf_url = `https://export.arxiv.org/pdf/${arxivId}.pdf`
        setPaperData((prev) => ({
          ...prev,
          pdf_url: pdf_url,
        }))
      } catch (error) {
        console.error('Error parsing arXiv URL:', error)
      }
    }
  }

  const handlePastPaperSelect = async (paper) => {
    try {
      const data = await researchService.getPaperById(paper.id)
      setPaperData({
        ...data,
        pdf_url: data.pdf_url,
      })

      // Set the paper URL in the Analyze Paper input
      setPaperUrl(data.pdf_url || `https://arxiv.org/abs/${paper.arxiv_id}`)
      setSelectedPastPaper(paper)
    } catch (error) {
      console.error('Error loading past paper:', error)
      toast.error('Failed to load past analysis')
    }
  }

  // Update loading indicator in the UI
  const isAnalyzing = Boolean(taskId)

  return (
    <div className="h-screen flex flex-col">
      <div className="flex-1 min-h-0">
        <div className="h-full max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 py-8 flex flex-col">
          <div className="mb-4">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-2xl font-semibold text-gray-900">
                  Research Paper Analysis
                </h1>
                <p className="mt-2 text-sm text-gray-700">
                  Analyze research papers from arXiv to get summaries, key
                  findings, and Q&A
                </p>
              </div>
            </div>
          </div>

          <div className="flex-1 min-h-0">
            <div className="h-full grid grid-cols-1 lg:grid-cols-2 gap-8">
              <div className="h-full min-h-0">
                <PDFViewer url={paperData.pdf_url} />
              </div>

              <div className="h-full min-h-0">
                <AnalysisPanel data={paperData} />
              </div>
            </div>
          </div>

          <DynamicIsland
            papers={pastPapers}
            selectedPaper={selectedPastPaper}
            onSelectPaper={handlePastPaperSelect}
            onSubmit={handleSubmit}
            loading={loading}
            isAnalyzing={isAnalyzing}
            paperUrl={paperUrl}
            setPaperUrl={setPaperUrl}
            activeView={activeView}
            setActiveView={setActiveView}
          />
        </div>
      </div>
    </div>
  )
}

export default DashboardResearch
